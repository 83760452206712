import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Subscription } from "rxjs";
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-patient-sidebar',
  templateUrl: './patient-sidebar.component.html',
  styleUrls: ['./patient-sidebar.component.scss']
})
export class PatientSidebarComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild("patientsidebar") patientsidebar: ElementRef;
  activeTab : string;
  isOpen = true;
  show = false;
  layoutSub: Subscription;

  constructor(private breakpointObserver: BreakpointObserver, private renderer: Renderer2) {}

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event) {
    const scrollPosition = window.scrollY || document.documentElement.scrollTop;
    const isSmallScreen = this.breakpointObserver.isMatched('(min-width: 1201px)');
    console.log('Width',isSmallScreen)
    if (scrollPosition > 50) {
      if (isSmallScreen) {
        this.renderer.setStyle(this.patientsidebar.nativeElement, 'top', '50px');
      }
    } else {
      if (isSmallScreen) {
        this.renderer.setStyle(this.patientsidebar.nativeElement, 'top', '100px');
      }
    }
  }

  togglePatientsidebar() {
    if (this.isOpen) {
      this.renderer.removeClass(this.patientsidebar.nativeElement, "open");
      this.isOpen = false;
    } else {
      this.renderer.addClass(this.patientsidebar.nativeElement, "open");
      this.isOpen = true;
    }
  }

  SecIns(e){
    this.show = e;
  }

  ngOnInit(): void {}


  ngAfterViewInit(): void {
  
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

}
