<div #patientsidebar class="patient-sidebar" [ngClass]="{open: isOpen}">
    <a class="patient-sidebar-toggle bg-primary" id="patient-sidebar-toggle-icon"
       (click)="togglePatientsidebar()">
       <i class="font-medium-1 white align-middle"
       [ngClass]="{
           'ft-chevrons-left' : isOpen,
           'ft-chevrons-right' : !isOpen
       }">
       </i>
     </a>
     <div [perfectScrollbar] class="patient-sidebar-content p-3">
       <div>
         <div class="media">
           <div class="media-left align-self-center pr-2">
             <img class="float-left rounded" src="https://imgs.search.brave.com/qdCjYlJ38wgED0igJWjfmAQUtGyUtw6B65fwnpeX7QQ/rs:fit:860:0:0/g:ce/aHR0cHM6Ly90My5m/dGNkbi5uZXQvanBn/LzAxLzA5LzAwLzY0/LzM2MF9GXzEwOTAw/NjQyNl8zODhQYWdx/aWVsZ2pGVEFNZ1c1/OWpSYURtUEp2U0JV/TC5qcGc" height="90px" />
           </div>
           <div class="media-body text-left">
             <h5 class="card-text">Goldstein Nancy</h5>
             <span>
               <b class="text-muted">Date of Birth <i class="ft-calendar"></i>:</b> 12/4/2023
             </span> 
             <br />
             <span>
               <b class="text-muted">Gender <i class="ft-user"></i>:</b> Female
             </span>
           </div>
         </div>
         <div class="row pb-2 mb-2 border-bottom-primary border-bottom-3">
           <div class="col-12">
             <span>
               <b class="">Gender Identity:</b>
             </span>
             <p class="mb-1">
               Lorem ipsum dolor sit amet, consectetur adipiscing elit
             </p>
           </div>
           <div class="col-12">
             <span>
               <b class="">Summary:</b>
             </span>
             <p class="mb-1">
               Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
             </p>
           </div>
         </div>
         <div class="row pb-2 mb-2 border-bottom-primary border-bottom-3">
           <div class="col-12">
             <h5 class="pb-2">Appointments</h5>
           </div>
           <div class="col-12">
             <div class="d-flex flex-row">
               <div>
                 <div class="text-muted mr-3 mb-3">
                   Today
                 </div>
                 <div class="text-muted mr-3 mb-3">
                   Next
                 </div>
                 <div class="text-muted mr-3 mb-3">
                   Last
                 </div>
               </div>
               <div>
                 <div class="mb-3">
                   --
                 </div>
                 <div class="mb-3">
                   <a type="button" class="text-primary">
                       Schedule Appointment
                   </a>
                 </div>
                 <div class="mb-3">
                   <span class="text-bold-600 mr-1">
                     12/09/2023 ,
                   </span>
                   <span class="text-bold-600 text-muted mr-1">
                     12:00Pm - 01:00PM
                   </span>
                   Nancy Goldstein
                 </div>
               </div>
             </div>
           </div>
         </div>
         <ngb-accordion class="patient-side" [closeOthers]="true" activeIds="1">
           <ngb-panel id="static-1" >
             <ng-template ngbPanelTitle>
               <span>Demographics</span>
             </ng-template>
             <ng-template ngbPanelContent>
               <form>
                 <div class="form-row">
                   <div class="col-12">
                     <h6 class="text-bold-600 pb-1 -ml-20">Legal Name</h6>
                   </div>
                   <div class="col-12">
                     <div class="form-group mb-2">
                       <label>First Name</label>
                       <input type="text" class="form-control" />
                     </div>
                   </div>
                   <div class="col-12">
                     <div class="form-group mb-2">
                       <label>Middle Name</label>
                       <input type="text" class="form-control" />
                     </div>
                   </div>
                   <div class="col-12">
                     <div class="form-group mb-2">
                       <label>Last Name</label>
                       <input type="text" class="form-control" />
                     </div>
                   </div>
                 </div>
                 <hr class="-mr20 -ml-20" />
                 <div class="form-row">
                   <div class="col-12">
                     <h6 class="text-bold-600 pb-1 -ml-20">Name to use</h6>
                   </div>
                   <div class="col-12">
                     <div class="form-group mb-2">
                       <label>First Name</label>
                       <input type="text" class="form-control" />
                     </div>
                   </div>
                   <div class="col-12">
                     <div class="form-group mb-2">
                       <label>Middle Name</label>
                       <input type="text" class="form-control" />
                     </div>
                   </div>
                   <div class="col-12">
                     <div class="form-group mb-2">
                       <label>Last Name</label>
                       <input type="text" class="form-control" />
                     </div>
                   </div>
                 </div>
                 <hr class="-mr20 -ml-20" />
                 <div class="form-row">
                   <div class="col-12">
                     <h6 class="text-bold-600 pb-1 -ml-20">Former name</h6>
                   </div>
                   <div class="col-12">
                     <div class="form-group mb-2">
                       <label>First Name</label>
                       <input type="text" class="form-control" />
                     </div>
                   </div>
                   <div class="col-12">
                     <div class="form-group mb-2">
                       <label>Middle Name</label>
                       <input type="text" class="form-control" />
                     </div>
                   </div>
                   <div class="col-12">
                     <div class="form-group mb-2">
                       <label>Last Name</label>
                       <input type="text" class="form-control" />
                     </div>
                   </div>
                 </div>
                 <hr class="-mr20 -ml-20" />
                 <div class="form-row">
                   <div class="col-12">
                     <h6 class="text-bold-600 pb-1 -ml-20">General Information</h6>
                   </div>
                   <div class="col-12">
                     <div class="form-group mb-2">
                       <label>Date of birth</label>
                       <input type="date" class="form-control" />
                     </div>
                   </div>
                   <div class="col-12">
                     <label>Birth sex</label>
                     <div class="btn-group btn-group-toggle mb-2" ngbRadioGroup name="radioBasic">
                       <label ngbButtonLabel class="btn-primary">
                           <input ngbButton type="radio" [value]="1"> F
                       </label>
                       <label ngbButtonLabel class="btn-primary">
                           <input ngbButton type="radio" [value]="2"> M
                       </label>
                       <label ngbButtonLabel class="btn-primary">
                           <input ngbButton type="radio" [value]="3"> X
                       </label>
                       <label ngbButtonLabel class="btn-primary">
                           <input ngbButton type="radio" [value]="4"> UnKnown
                       </label>
                     </div>
                   </div>
                   <div class="col-12">
                     <label>Legal sex</label>
                     <div class="btn-group btn-group-toggle mb-2" ngbRadioGroup name="radioBasic1">
                       <label ngbButtonLabel class="btn-outline-primary">
                           <input ngbButton type="radio" [value]="1"> F
                       </label>
                       <label ngbButtonLabel class="btn-outline-primary">
                           <input ngbButton type="radio" [value]="2"> M
                       </label>
                       <label ngbButtonLabel class="btn-outline-primary">
                           <input ngbButton type="radio" [value]="3"> X
                       </label>
                       <label ngbButtonLabel class="btn-outline-primary">
                           <input ngbButton type="radio" [value]="4"> UnKnown
                       </label>
                     </div>
                   </div>
                   <div class="col-12">
                     <div class="form-group mb-2">
                       <label>Pronouns</label>
                       <ng-select appendTo="body" [multiple]="true" [closeOnSelect]="false" placeholder="Select Pronouns">
                         <ng-option [value]="1">she/her/hers</ng-option>
                         <ng-option [value]="2">he/him/his</ng-option>
                     </ng-select>
                     </div>
                   </div>
                   <div class="col-12">
                     <div class="form-group mb-2">
                       <label>Gender identity</label>
                       <ng-select appendTo="body" placeholder="Select Gender">
                         <ng-option [value]="1">Male</ng-option>
                         <ng-option [value]="2">Female</ng-option>
                     </ng-select>
                     </div>
                   </div>
                   <div class="col-12">
                     <div class="form-group mb-2">
                       <label>Race</label>
                       <ng-select appendTo="body" [multiple]="true" [closeOnSelect]="false"  placeholder="Select race">
                         <ng-option [value]="1">Asian</ng-option>
                         <ng-option [value]="2">While</ng-option>
                         <ng-option [value]="3">Unknown</ng-option>
                         <ng-option [value]="4">Decline to answer</ng-option>
                     </ng-select>
                     </div>
                   </div>
                   <div class="col-12">
                     <div class="form-group mb-2">
                       <label>Race subcategory</label>
                       <ng-select appendTo="body" class="cursor-not-allowed" placeholder="Select Race subcategory">
                       </ng-select>
                     </div>
                   </div>
                   <div class="col-12">
                     <div class="form-group mb-2">
                       <label>Ethnicity</label>
                       <ng-select appendTo="body" placeholder="Select Ethnicity">
                       </ng-select>
                     </div>
                   </div>
                   <div class="col-12">
                     <div class="form-group mb-2">
                       <label>Specific ethnicity</label>
                       <ng-select appendTo="body" class="cursor-not-allowed" placeholder="Select Ethnicity">
                       </ng-select>
                     </div>
                   </div>
                   <div class="col-12">
                     <div class="form-group mb-2">
                       <label>Additional Notes</label>
                       <textarea class="form-control" rows="4"></textarea>
                     </div>
                   </div>
                   <div class="col-12 text-right">
                     <button class="btn btn-sm btn-secondary mr-2">
                       <i class="ft-delete"></i> Close
                     </button>
                     <button class="btn btn-sm btn-primary">
                       <i class="ft-save"></i> Save
                     </button>
                   </div>
                 </div>
               </form>
             </ng-template>
           </ngb-panel>
           <ngb-panel id="static-2">
             <ng-template ngbPanelTitle>
               <span>Insurance</span>
             </ng-template>
             <ng-template ngbPanelContent>
               <form>
                 <div class="form-row">
                   <div class="col-12">
                     <h6 class="text-bold-600 pb-1 -ml-20">Insurance info</h6>
                   </div>
                   <div class="col-12">
                     <div class="form-group mb-2">
                       <label>Company name</label>
                       <input type="text" class="form-control" />
                     </div>
                   </div>
                   <div class="col-12">
                     <div class="form-group mb-2">
                       <label>Member ID</label>
                       <input type="text" class="form-control" />
                     </div>
                   </div>
                   <div class="col-12">
                     <div class="form-group mb-2">
                       <label>Group ID</label>
                       <input type="text" class="form-control" />
                     </div>
                   </div>
                   <div class="col-12">
                     <div class="form-group mb-2">
                       <label>Information release</label>
                       <ng-select>
                         <ng-option [value]="1">Yes</ng-option>
                         <ng-option [value]="2">No</ng-option>
                       </ng-select>
                     </div>
                   </div>
                   <div class="col-12">
                     <div class="form-group mb-2">
                       <label>Payment authorization</label>
                       <ng-select>
                         <ng-option [value]="1">Yes</ng-option>
                         <ng-option [value]="2">No</ng-option>
                       </ng-select>
                     </div>
                   </div>
                 </div>
                 <hr class="-mr20 -ml-20" />
                 <div class="form-row">
                   <div class="col-12">
                     <h6 class="text-bold-600 pb-1 -ml-20">Insurance subscriber info</h6>
                   </div>
                   <div class="col-12">
                     <div class="form-group mb-2">
                       <label>Relationship to subscriber</label>
                       <ng-select>
                         <ng-option [value]="1">Parent</ng-option>
                         <ng-option [value]="2">Spouse</ng-option>
                         <ng-option [value]="3">Sibling</ng-option>
                         <ng-option [value]="4">Self</ng-option>
                         <ng-option [value]="5">Child</ng-option>
                         <ng-option [value]="6">Other</ng-option>
                       </ng-select>
                     </div>
                   </div>
                 </div>
                 <hr class="-mr20 -ml-20" />
                 <div class="form-row">
                   <div class="col-12">
                     <h6 class="text-bold-600 pb-1 -ml-20">Insurance card</h6>
                   </div>
                   <div class="col-12">
                     <div class="form-group mb-3">
                       <label>Card Front</label>
                       <input type="file" class="form-control-file" >
                     </div>
                   </div>
                   <div class="col-12">
                     <div class="form-group mb-2">
                       <label>Card Back</label>
                       <input type="file" class="form-control-file" >
                     </div>
                   </div>
                 </div>
                 <hr class="-mr20 -ml-20" />
                 <div class="form-row">
                   <div class="col-12">
                     <h6 class="text-bold-600 pb-1 -ml-20">Secondary insurance</h6>
                   </div>
                 </div>
                 <div class="form-row" *ngIf="this.show === true">
                   <div class="col-12">
                     <div class="form-group mb-2">
                       <label>Company name</label>
                       <input type="text" class="form-control" />
                     </div>
                   </div>
                   <div class="col-12">
                     <div class="form-group mb-2">
                       <label>Member ID</label>
                       <input type="text" class="form-control" />
                     </div>
                   </div>
                   <div class="col-12">
                     <div class="form-group mb-2">
                       <label>Group ID</label>
                       <input type="text" class="form-control" />
                     </div>
                   </div>
                   <div class="col-12">
                     <div class="form-group mb-2">
                       <label>Information release</label>
                       <ng-select>
                         <ng-option [value]="1">Yes</ng-option>
                         <ng-option [value]="2">No</ng-option>
                       </ng-select>
                     </div>
                   </div>
                   <div class="col-12">
                     <div class="form-group mb-2">
                       <label>Payment authorization</label>
                       <ng-select>
                         <ng-option [value]="1">Yes</ng-option>
                         <ng-option [value]="2">No</ng-option>
                       </ng-select>
                     </div>
                   </div>
                   <div class="col-12">
                     <div class="form-group mb-2">
                       <label>Relationship to subscriber</label>
                       <ng-select>
                         <ng-option [value]="1">Parent</ng-option>
                         <ng-option [value]="2">Spouse</ng-option>
                         <ng-option [value]="3">Sibling</ng-option>
                         <ng-option [value]="4">Self</ng-option>
                         <ng-option [value]="5">Child</ng-option>
                         <ng-option [value]="6">Other</ng-option>
                       </ng-select>
                     </div>
                   </div>
                 </div>
                 <div class="form-row">
                   <div class="col-12">
                     <button class="btn btn-sm btn-outline-primary mb-2" *ngIf="this.show === false" (click)="SecIns(true)">
                       <i class="ft-plus mr-1"></i>Add Secondary Ins
                     </button>
                     <button class="btn btn-sm btn-danger mb-2" *ngIf="this.show === true" (click)="SecIns(false)">
                       <i class="ft-trash-2 mr-1"></i>Remove Secondary Ins
                     </button>
                   </div>
                 </div>
                 <hr class="-mr20 -ml-20" />
                 <div class="form-row mb-2">
                   <div class="col-12 text-right">
                     <button class="btn btn-sm btn-secondary mr-2">
                       <i class="ft-delete"></i> Close
                     </button>
                     <button class="btn btn-sm btn-primary">
                       <i class="ft-save"></i> Save
                     </button>
                   </div>
                 </div>
               </form>
             </ng-template>
           </ngb-panel>
         </ngb-accordion>
       </div>
     </div>
   </div>
   